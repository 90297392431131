import React, { FunctionComponent } from 'react';
import { BreakPoints } from '../common-styles';
import styled from 'styled-components';
import { requireResources } from '../utils';
import { externalLinks } from '../pages/main/data';
import { DesktopHeader, MobileHeader, HeaderLink, GroupedLinks } from '@npmsoluto/ae-hub-components-header';

const [sdkLogo] = requireResources(['sdk-logo.svg']);

const StyledDesktopHeader = styled(DesktopHeader)`
    display: none;
    @media ${BreakPoints.desktop} {
        display: block;
        height: ${({ theme }) => theme.sizes.desktopHeaderHeight};
    }
`;

const StyledMobileHeader = styled(MobileHeader)`
    display: block;
    height: ${({ theme }) => theme.sizes.mobileHeaderHeight};

    @media ${BreakPoints.desktop} {
        display: none;
    }
`;

const backofficeLink: HeaderLink = {
    displayName: 'Backoffice',
    href: externalLinks.backoffice,
};

const documentationLinks: GroupedLinks = {
    title: 'Documentation',
    links: [
        {
            displayName: 'Web Api',
            href: externalLinks.webApiDocumentation,
            target: '_blank',
        },
        {
            displayName: 'Native Api (React Native/iOS/Android)',
            href: externalLinks.nativeApiDocumentation,
            target: '_blank',
        },
    ],
};

const links = [backofficeLink, documentationLinks];

const Heading: FunctionComponent = () => (
    <>
        <StyledDesktopHeader logoSrc={sdkLogo} title='Anywhere Expert SDK' links={links} logoClickUrl='https://ae-sdk.io/' />
        <StyledMobileHeader logoSrc={sdkLogo} title='AE SDK' links={links} logoClickUrl='https://ae-sdk.io/' />
    </>
);

export default Heading;
