const scrollFactor = 75;

const animateHorizontalScroll = (element: HTMLElement, position: number): void => {
    const isLeftDirection = element.scrollLeft > position;
    const calcNewPosition = (): number =>
        isLeftDirection ? element.scrollLeft - scrollFactor : element.scrollLeft + scrollFactor;

    const tick = (): void => {
        if (element.scrollLeft === position) {
            return;
        }
        const maxScroll = element.scrollWidth - element.clientWidth;
        const newPosition = calcNewPosition();

        const isLastStep = (isLeftDirection && newPosition <= position) || (!isLeftDirection && newPosition >= position);

        element.scrollLeft = isLastStep ? position : newPosition;
        if (isLastStep || element.scrollLeft === maxScroll || element.scrollLeft === 0) {
            return;
        }
        window.requestAnimationFrame(tick);
    };
    window.requestAnimationFrame(tick);
};

export default animateHorizontalScroll;
