import React, { FunctionComponent } from 'react';
import CarouselImageList from './CarouselImageList';

type Props = {
    images: string[];
};

const ScrollCarousel: FunctionComponent<Props> = ({ images }) => {
    return (
        <div>
            <CarouselImageList imageList={images} />
        </div>
    );
};

export default ScrollCarousel;
