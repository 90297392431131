import { useState, useCallback, useEffect, MutableRefObject } from 'react';

export default function useHorizontalDrag(
    elementRef: MutableRefObject<any>
): (ev: React.MouseEvent<HTMLUListElement, MouseEvent>) => void {
    const [isScrolling, setIsScrolling] = useState(false);
    const [scrollLeft, setScrollLeft] = useState(0);
    const [clientX, setClientX] = useState(0);

    const handleMouseDown = useCallback(
        (ev: React.MouseEvent<HTMLUListElement, MouseEvent>) => {
            setIsScrolling(true);
            setScrollLeft(elementRef.current.scrollLeft);
            setClientX(ev.clientX);
        },
        [elementRef]
    );

    const handleMouseUp = useCallback(() => {
        setIsScrolling(false);
        setScrollLeft(0);
        setClientX(0);
    }, []);

    const handleMouseMove = useCallback(
        (ev: MouseEvent) => {
            elementRef.current.scrollLeft = scrollLeft + clientX - ev.clientX;
        },
        [scrollLeft, clientX, elementRef]
    );

    useEffect(() => {
        if (!isScrolling) return;
        window.addEventListener('mousemove', handleMouseMove as any);
        window.addEventListener('mouseup', handleMouseUp);
        return () => {
            window.removeEventListener('mousemove', handleMouseMove as any);
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isScrolling]);

    return handleMouseDown;
}
