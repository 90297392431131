import React, { useEffect, FunctionComponent } from 'react';
import IntroSection from './sections/IntroSection';
import { BreakPoints } from '../../common-styles';
import styled from 'styled-components';
import SdkBehaviorsSection from './sections/SdkBehaviorsSection';
import PrinciplesSection from './sections/PrinciplesSection';
import CapablitiesSection from './sections/CapablitiesSection';
import DocumentationSection from './sections/DocumentationSection';
import ContactSection from './sections/ContactSection';
import { RouteProps } from 'react-router-dom';
import { loadSDK } from './SDK-loader';

const Container = styled.div`
    position: relative;
    margin-top: ${({ theme }) => theme.sizes.mobileHeaderHeight};
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    @media ${BreakPoints.desktop} {
        margin-top: ${({ theme }) => theme.sizes.desktopHeaderHeight};
    }

    @media ${BreakPoints.desktopContentWidth} {
        width: ${({ theme }) => theme.sizes.desktopContentWidth};
        margin-right: auto;
        margin-left: auto;
    }
`;

const MainPage: FunctionComponent = (_: RouteProps) => {
    useEffect(() => {
        loadSDK();
    }, []);

    return (
        <Container>
            <IntroSection />
            <SdkBehaviorsSection />
            <PrinciplesSection />
            <CapablitiesSection />
            <DocumentationSection />
            <ContactSection />
        </Container>
    );
};

export default MainPage;
