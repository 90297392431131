import React, { useState, useImperativeHandle, useRef, RefObject } from 'react';

export type WobbleProps = {
    showShadow?: boolean;
    animate?: boolean;
    duration?: number;
    infinite?: boolean;
    animateRef?: RefObject<unknown>;
    baseFrequency?: string;
    id: string;
};

export type WobbleRefActions = {
    play: (callback?: VoidFunction) => void;
};

const WobbleFilter: (props: WobbleProps) => JSX.Element = ({
    duration = 2000,
    infinite = true,
    baseFrequency = '0.15',
    animateRef,
    id,
}: WobbleProps) => {
    const [hasPlayed, setHasPlayed] = useState(false);
    const colorMatrixAnimationRef = useRef<any>(null);
    const displacementMapAnimationRef = useRef<any>(null);

    const repeat = infinite ? 'indefinite' : 'once';

    const play = (callback?: VoidFunction): void => {
        if (!colorMatrixAnimationRef.current) return;

        try {
            // getStartTime returns the current time in the animation
            // if there's no animation runnning it will throw an error in chrome
            // and will return Infinite in ios
            const startTime: number = colorMatrixAnimationRef.current.getStartTime();
            if (!isFinite(startTime)) {
                throw new Error();
            }
        } catch {
            if (!colorMatrixAnimationRef.current.beginElement) return;
            // beginElement will start the animation
            colorMatrixAnimationRef.current.beginElement();

            if (displacementMapAnimationRef.current) {
                displacementMapAnimationRef.current.beginElement();
            }

            if (callback) {
                colorMatrixAnimationRef.current.addEventListener('endEvent', callback, {
                    once: true,
                });
            }

            setHasPlayed(true);
        }
    };

    useImperativeHandle(
        animateRef,
        (): WobbleRefActions => ({
            play,
        })
    );

    return (
        <defs>
            <filter id={id} filterUnits='objectBoundingBox' colorInterpolationFilters='sRGB'>
                <feTurbulence type='fractalNoise' baseFrequency={baseFrequency} numOctaves='3' />
                <feColorMatrix type='hueRotate' values='360'>
                    <animate
                        attributeName='values'
                        values='0;360'
                        dur={`${duration}ms`}
                        calcMode='spline'
                        keySplines='0 0 0 0'
                        repeatCount={repeat}
                        ref={colorMatrixAnimationRef}
                        begin='indefinite'
                    />
                </feColorMatrix>
                <feDisplacementMap
                    in='SourceGraphic'
                    scale={infinite || !hasPlayed ? '20' : '0'}
                    xChannelSelector='B'
                    yChannelSelector='R'
                >
                    {!infinite && (
                        <animate
                            attributeName='scale'
                            values='20; 0'
                            dur={`${duration}ms`}
                            calcMode='spline'
                            keySplines='0 0 0 0'
                            repeatCount={'once'}
                            ref={displacementMapAnimationRef}
                            begin='indefinite'
                        />
                    )}
                </feDisplacementMap>
                <feGaussianBlur stdDeviation='10' />
                <feColorMatrix type='matrix' values='1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 30 -14' />
                <feComposite in='SourceGraphic' operator='atop' />
            </filter>
        </defs>
    );
};

export default WobbleFilter;
