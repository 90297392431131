import React, { FunctionComponent, useMemo, useCallback } from 'react';
import styled from 'styled-components';

const StyledBulletPoint = styled.li<{ selected: boolean }>`
    width: 0.8rem;
    height: 0.8rem;
    margin: 0 0.3rem;
    border-radius: 50%;
    background-color: ${({ selected, theme }) => (selected ? theme.colors.primary : theme.colors.appBlack)};
    cursor: pointer;
    transition: transform 0.2s;
    transform: translate3d(0, 0, 0) ${({ selected }) => (selected ? 'scale(1)' : 'scale(.6)')};

    &:hover {
        transform: translate3d(0, 0, 0) scale(1);
    }
`;

type BulletPointProps = {
    index: number;
    selected: boolean;
    onClick?: (ev: any) => void;
};
const BulletPoint: FunctionComponent<BulletPointProps> = ({ selected, index, onClick }) => {
    const _onClick = useCallback(() => onClick && onClick(index), [index]);
    return <StyledBulletPoint onClick={_onClick} selected={selected} />;
};

const BulletPointsContainer = styled.ul`
    display: inline-flex;
`;

type BulletPointsProps = {
    onBulletClick?: (index: number) => void;
    selectedIndex?: number;
    count: number;
};

const BulletPoints: FunctionComponent<BulletPointsProps> = ({ onBulletClick, count, selectedIndex }) => {
    const countArray = useMemo(() => [...Array(count).keys()], [count]);
    return (
        <BulletPointsContainer>
            {countArray.map((_, index) => (
                <BulletPoint onClick={onBulletClick} selected={index === selectedIndex} index={index} key={index} />
            ))}
        </BulletPointsContainer>
    );
};

export default BulletPoints;
