import { requireResources } from '../utils';
import { Assets } from '../theme';

const [mailIcon, slackIcon, sdkShape] = requireResources(['mail-icon.png', 'slack-mark.png', 'sdk-shape.svg']);

const assets: Assets = {
    emailIconUrl: mailIcon,
    slackIconUrl: slackIcon,
    aeSdkIconUrl: sdkShape,
};

export default assets;
