import React, { FunctionComponent, useCallback, useRef, useState } from 'react';
import useHorizontalDrag from './useHorizontalDrag';
import CarouselImageItem from './CarouselImageItem';
import useItemInView from './useItemInView';
import BulletPoints from './BulletPoints';
import { BreakPoints } from '../../common-styles';
import animateHorizontalScroll from './animateHorizontalScroll';
import styled from 'styled-components';

const Container = styled.div`
    text-align: center;
`;

const StyledList = styled.ul`
    display: flex;
    align-items: center;
    overflow-x: auto;
    width: 100%;
    cursor: grab;
    padding-bottom: 2rem;
    padding: 0 3rem;
    &:active {
        cursor: grabbing;
    }

    -ms-overflow-style: none; /* Hide scroll bar in Internet Explorer */
    scrollbar-width: none; /* Hide scroll bar in Firefox */
    &::-webkit-scrollbar {
        display: none;
    }

    @media ${BreakPoints.desktop} {
        padding-left: calc((100vw - ${({ theme }) => theme.sizes.desktopContentWidth}) / 2);
    }
`;

type Props = {
    imageList: string[];
};

const CarouselImageList: FunctionComponent<Props> = ({ imageList }) => {
    const carouselRef = useRef<HTMLUListElement>(null);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const handleMouseDown = useHorizontalDrag(carouselRef);
    const carouselItemsRefs = useRef(imageList.map(() => React.createRef<any>()));
    const { handleScroll } = useItemInView(carouselRef, carouselItemsRefs, (index) => setSelectedIndex(index));

    const getItemPosition = useCallback(
        (index: number) => {
            if (index === 0 || !carouselRef.current) return 0;
            if (index === carouselItemsRefs.current.length - 1) return carouselRef.current.scrollWidth;

            const carouselItemRef = carouselItemsRefs.current[index];
            return (
                carouselItemRef.current.offsetLeft + carouselItemRef.current.clientWidth / 2 - carouselRef.current.clientWidth / 2
            );
        },
        [carouselItemsRefs]
    );

    const handleBulletClick = useCallback((index) => {
        if (!carouselRef.current) return;
        animateHorizontalScroll(carouselRef.current, getItemPosition(index));
    }, []);
    return (
        <Container>
            <StyledList ref={carouselRef} className='ae-sdk-carousel-list' onMouseDown={handleMouseDown} onScroll={handleScroll}>
                {imageList.map((image, index) => (
                    <CarouselImageItem imageSrc={image} key={index} ref={carouselItemsRefs.current[index]} />
                ))}
            </StyledList>
            <div>
                <BulletPoints count={imageList.length} selectedIndex={selectedIndex} onBulletClick={handleBulletClick} />
            </div>
        </Container>
    );
};

export default CarouselImageList;
