import React, { FunctionComponent } from 'react';
import { Section, SectionTitle, FluidContainer } from '../../common/styles';
import { BreakPoints } from '../../../common-styles';
import Capabilities, { Capability } from '../components/Capabilities';
import { externalLinks } from '../data';
import SiteLinkButton from '../../../components/SiteLinkButton';
import { requireResources } from '../../../utils';
import styled from 'styled-components';

const [baseCampIcon] = requireResources(['base-camp.png']);

const capabilities: Capability[] = [
    {
        title: 'Make it yours',
        description: 'Control over theme color, fonts and other assets through a dedicated backoffice and no code change',
    },
    {
        title: 'Accessibility',
        description: 'Compliant with AT&T’s strict accessibility guidelines (CATO)',
    },
    {
        title: 'Customized Experience',
        description: 'Dynamically alter the experience and routing of your sessions',
    },
    {
        title: 'Proactive Approach',
        description: 'Invite customers to start a session in a specific context',
    },
    {
        title: 'Documentation',
        description: 'Everything you need to start working with the SDK',
    },
    {
        title: 'Responsiveness',
        description: 'Supporting both desktop and mobile',
    },
    {
        title: 'Authentication',
        description: 'MDN-based authentication (code texted to user’s phone) and custom logins',
    },
    {
        title: 'Events & Analytics',
        description: 'Support measuring SDK’s performance and user’s behaviour',
    },
    {
        title: 'Additional channels',
        description: 'Voice session support and in the future video as well',
    },
];

const CapabilitiesContainer = styled.div`
    @media ${BreakPoints.desktop} {
        margin-bottom: ${({ theme }) => theme.sizes.desktopSectionElementsSpacing};
    }
`;

const StyledLinkButton = styled(SiteLinkButton)`
    width: 250px;
`;

const StyledFluidContainer = styled(FluidContainer)`
    position: relative;

    @media ${BreakPoints.desktopContentWidth} {
        padding-right: 0;
        padding-left: 0;
    }
`;

const ContentDiv = styled.div`
    max-width: ${({ theme }) => theme.sizes.desktopContentWidth};
    margin: 0 auto;
`;

const CapablitiesSection: FunctionComponent = () => (
    <Section>
        <StyledFluidContainer>
            <ContentDiv>
                <SectionTitle>
                    Our capablities and feature set are growing constantly. Here <br /> are our current offerings and what’s
                    upcoming.
                </SectionTitle>
                <CapabilitiesContainer>
                    <Capabilities capabilities={capabilities} />
                </CapabilitiesContainer>
                <div>
                    <StyledLinkButton targetSrc={externalLinks.baseCamp} text='Follow us on Basecamp' iconSrc={baseCampIcon} />
                </div>
            </ContentDiv>
        </StyledFluidContainer>
    </Section>
);

export default CapablitiesSection;
