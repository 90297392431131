import React, { FunctionComponent, useCallback } from 'react';
import { BreakPoints } from '../../../common-styles';
import styled from 'styled-components';
import { ContentAdjustedContainer, Section } from '../../common/styles';
import SiteLinkButton from '../../../components/SiteLinkButton';
import { externalLinks } from '../data';
import useTheme from '../../../hooks/useTheme';
import SiteButton from '../../../components/SiteButton';

const StyledSection = styled(Section)`
    position: relative;
    margin-bottom: 0;
    padding: 0;
    width: 100vw;
    display: flex;
    justify-content: center;
    @media ${BreakPoints.desktopContentWidth} {
        margin-bottom: 0;
    }
`;

const BlobShapeContainer = styled.div`
    background-color: ${({ theme }) => theme.colors.lightgreen};
    width: 100vw;
    padding: 30px 0;
    @media ${BreakPoints.desktopContentWidth} {
        width: 90vw;
    }
`;

const Title = styled.h3`
    font-size: 2.8rem;
    margin-bottom: 40px;
    margin-top: 63px;
    line-height: 1;
    font-weight: 600;

    @media ${BreakPoints.desktop} {
        font-size: 4.8rem;
        margin-bottom: ${({ theme }) => theme.sizes.desktopSectionElementsSpacing};
    }
`;

const Description = styled.p`
    font-size: 1.6rem;
    margin-bottom: 40px;
    padding: 0 15px;
    font-weight: 600;

    @media ${BreakPoints.desktop} {
        font-size: 2.6rem;
        font-weight: 400;
        width: 940px;
        margin: 0 auto;
        padding: 0;
        margin-bottom: ${({ theme }) => theme.sizes.desktopSectionElementsSpacing};
    }
`;

const LinkContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 100px;

    > * {
        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }

    @media ${BreakPoints.desktop} {
        flex-direction: row;
        justify-content: center;

        > * {
            &:not(:last-child) {
                margin: 0 18px 0 0;
            }
        }
    }
`;

const _window = window as any;

const ContactSection: FunctionComponent = () => {
    const theme = useTheme();
    const handleMessageUsClick = useCallback(() => {
        _window.AE_SDK && _window.AE_SDK.triggerMessagingOverlay();
    }, []);

    return (
        <StyledSection>
            <BlobShapeContainer>
                <ContentAdjustedContainer>
                    <Title>#remote-serviceability</Title>
                    <Description>
                        We would love to hear any question, suggestion or a request that comes to mind. We'll do our best to
                        answer as soon as we can but keep in mind it will usually be in your early morning hours, Sunday through
                        Thursday.
                    </Description>
                    <LinkContainer>
                        <SiteLinkButton text='Email us' targetSrc={externalLinks.mailTo} iconSrc={theme.assets.emailIconUrl} />
                        <SiteLinkButton text='Slack us' targetSrc={externalLinks.slack} iconSrc={theme.assets.slackIconUrl} />
                        <SiteButton text='Message us' iconSrc={theme.assets.aeSdkIconUrl} onClick={handleMessageUsClick} />
                    </LinkContainer>
                </ContentAdjustedContainer>
            </BlobShapeContainer>
        </StyledSection>
    );
};

export default ContactSection;
