import React, { ComponentType } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import MainPage from './pages/main';
import { ThemeProvider, variables, assets } from './common-styles';
import { createGlobalStyle } from 'styled-components';
import { Theme } from './theme';
import Heading from './components/Heading';

const { colors, sizes } = variables;
const theme: Theme = {
    colors,
    sizes,
    assets,
};

const GlobalStyle = createGlobalStyle`
  body {
    line-height: 1.25;
    font-size: 1.6rem;
    color: ${({ theme }) => theme.colors.appBlack};
    overflow-x: hidden;
  }
`;

const App: ComponentType = () => {
    return (
        <ThemeProvider theme={theme}>
            <GlobalStyle />
            <div>
                <Heading />
                <Router>
                    <Route path='/' exact={true} component={MainPage} />
                </Router>
            </div>
        </ThemeProvider>
    );
};

export default App;
