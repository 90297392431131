export {}; // this file needs to be a module

declare global {
    const globals: {
        USE_LOCAL_SDK: boolean;
        REACT_APP_MDN: string;
        REACT_APP_COUNTRY_CODE: string;
        REACT_APP_HOME_USER_CODE_AUTH_SECRET: string;
        REACT_APP_PARTNER: string;
        REACT_APP_IS_TEST: boolean;
    };
}
