import React, { FunctionComponent } from 'react';
import WobbleFilter from '../WobbleFilter';
import useWobble from '../useWobble';
import styled from 'styled-components';

const StyledWobble = styled.svg`
    max-width: 95%;
`;

const MainTitleWobble: FunctionComponent = () => {
    const { filterRef, vectorId } = useWobble();
    return (
        <StyledWobble xmlns='http://www.w3.org/2000/svg' width='800' height='500' fill='none' viewBox='0 0 310 289'>
            <g>
                <WobbleFilter animateRef={filterRef} id={vectorId} baseFrequency='0.01' />
                <path
                    style={{
                        filter: `url(#${vectorId})`,
                    }}
                    fill='#D2FA46'
                    d='M.055 115.735C-2.276 46.455 69.85 13.739 114.531 6.041c26.226-6.234 76.29-9.692 119.396 0 51.923 11.668 65.789 54.988 73.099 109.694 7.31 54.706 2.437 99.313-29.24 140.224-31.382 40.53-139.018 39.483-200.519 19.812a18.347 18.347 0 0 1-3.349-1.46C9.596 238.391.058 195.131.055 115.735z'
                />
            </g>
        </StyledWobble>
    );
};

export default MainTitleWobble;
