import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { BreakPoints } from '../../../../common-styles';
import { Capability } from './types';
import CapabilityItem from './CapabilityItem';

export * from './types';

const StyledCapabilitiesList = styled.ul`
    display: flex;
    flex-wrap: wrap;

    @media ${BreakPoints.desktop} {
        justify-content: space-between;
        max-width: 100%;
    }
`;

type Props = {
    capabilities: Capability[];
};

const Capabilities: FunctionComponent<Props> = ({ capabilities }) => (
    <StyledCapabilitiesList>
        {capabilities.map((capability, index) => (
            <CapabilityItem key={index} {...capability} />
        ))}
    </StyledCapabilitiesList>
);

export default Capabilities;
