import queryString from 'query-string';
const queryParams = queryString.parse(document.location?.search || '');
const customSdkFromBranchName = queryParams['branch'];
const shouldSkipSDKRolloutRaffle = queryParams['skip-raffle'] === 'true';
const majorVersion = queryParams['major'] || '3';
const isV3 = majorVersion === '3';
const appKey = queryParams['app-key'] || 'sdk-knowledge-hub';

const loadSdkSnippet = (sdkUrl: string): Promise<void> => {
    console.log('loading sdk from', sdkUrl);
    return new Promise((resolve) => {
        const w = window,
            a = '__AE_SDK_ACTIONS',
            d = document,
            s = d.createElement('script', {}),
            b = d.getElementsByTagName('script')[0];

        if (isV3) {
            w[a] = [];
            (w as any).AE_SDK = function (_) {
                w[a].push(_);
            };
        }

        s.type = 'text/javascript';
        s.async = true;
        s.defer = true;
        s.src = sdkUrl;
        s.onload = () => {
            resolve();
        };
        b?.parentNode?.insertBefore?.(s, b.nextSibling);
    });
};

const getSdkUrlFromQueryString = (): string => {
    if (!isV3 && !customSdkFromBranchName)
        return `https://assets.ae-sdk.io/production/v2/ae-sdk-loader.umd.js?skipRaffle=${shouldSkipSDKRolloutRaffle}`;

    const urlPrefix = `https://assets.ae-sdk.io/core${isV3 ? '/v3/' : '/'}`;
    const urlQueryParams = `?${isV3 ? `appKey=${appKey}&` : ''}skipRaffle=${shouldSkipSDKRolloutRaffle}`;

    return `${urlPrefix}${!!customSdkFromBranchName ? `branch/${customSdkFromBranchName}` : ''}${urlQueryParams}`;
};

export const loadSDK = async (): Promise<void> => {
    const shouldUseLocal = globals.USE_LOCAL_SDK === true;
    const sdkUrl = shouldUseLocal ? './sdk-assets/ae-sdk-core.umd.js' : getSdkUrlFromQueryString();
    await loadSdkSnippet(sdkUrl);

    const shouldInitializeTheSdk: boolean = queryParams['init-sdk'] !== 'false';
    if (!shouldInitializeTheSdk) return;

    if (isV3) {
        (window as any).AE_SDK(async (_, sdk) => {
            await sdk.initialize();
        });
    } else {
        (window as any).AE_SDK.initialize('sdk-knowledge-hub');
    }
};
