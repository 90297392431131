import React from 'react';

export default (): JSX.Element => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='76' height='76' fill='none' viewBox='0 0 76 76'>
            <path
                fill='#A6AAAF'
                fillRule='evenodd'
                d='M39.94 53.79A9.004 9.004 0 0 1 29.513 48c-1.947.01-3.718.226-4.906.51-3.748.649-9.797 3.408-9.601 9.252 0 6.36.722 9.972 5.418 12.914a7.075 7.075 0 0 0 1.926.838c5.247 1.404 13.46 1.308 15.95-1.926 2.656-3.45 3.065-7.212 2.452-11.826-.19-1.434-.434-2.775-.81-3.972z'
                clipRule='evenodd'
                className='svg-circle'
            />
            <path
                fill='#333F48'
                d='M59.838 18.492c-.605-1.165-1.768-.839-2.373-.699a361.952 361.952 0 0 1-5.863 1.491l-.418.094c-1.768.465-3.536.885-5.351 1.35l-1.303.327.838-2.423c1.395-3.914 2.745-7.875 4.14-11.79.28-.745.466-1.584.047-2.19C49.136 4.047 48.252 4 47.508 4c-2.885.047-5.956.093-9.725.14-1.349.046-2.745.046-4.094.046-2.606 0-5.304 0-8.003.187-.651.046-1.35.14-1.721.699-.373.512-.233 1.258-.047 1.91.419 1.445.838 2.843 1.256 4.287.838 2.89 1.722 5.918 2.56 8.9.325 1.212 1.07 1.865 2.326 2.051 1.814.28 3.629.606 5.35.886.233.046.512.093.745.14-1.07.279-2.14.558-3.21.791-2.187.513-4.467 1.072-6.654 1.771-.512.187-1.35.42-1.442 1.398-.14 1.119.837 1.585 1.163 1.77l1.907.933c.931.466 1.862.932 2.746 1.398 1.07.559 2.187 1.118 3.303 1.677l.838.42c-5.072 1.444-8.794 5.964-8.98 11.09-.233 5.08 2.512 9.506 7.165 11.557a12.43 12.43 0 0 0 4.979 1.072c3.164 0 6.328-1.212 8.654-3.542 2.652-2.61 3.955-6.151 3.583-9.693-.373-3.634-2.373-6.943-5.584-9.04-.14-.093-.186-.14-.232-.186l.232-.233c1.582-1.445 3.118-2.936 4.653-4.38 1.07-1.026 2.14-2.004 3.21-3.03.652-.605 1.257-1.21 1.908-1.817 1.536-1.444 3.071-2.935 4.7-4.333.372-.233 1.395-1.119.744-2.377zM37.877 36.246h.093c5.118.047 9.12 3.915 9.073 8.807 0 4.893-4.095 8.854-9.073 8.854-2.42 0-4.7-.932-6.375-2.656-1.628-1.63-2.559-3.821-2.512-6.151 0-2.283 1.023-4.567 2.745-6.29 1.628-1.632 3.862-2.564 6.049-2.564zm10.375-9.599c-2.093 1.957-4.28 4.007-6.374 6.058-.372.326-.512.373-.93.14-2.001-1.026-4.002-2.05-6.003-3.03l-3.908-1.957 21.775-5.498c-.605.56-1.21 1.165-1.814 1.724-.93.885-1.861 1.724-2.745 2.563zm-2.28-19.292c-1.721 4.753-3.21 9.04-4.606 13.001-.14.42-.186.42-.605.326a890.228 890.228 0 0 0-9.724-1.677c-.233-.047-.28-.093-.372-.326l-.42-1.398c-.93-3.216-1.86-6.478-2.837-9.74 0-.046 0-.046-.047-.093h.186l18.286-.28h.14c.046.094 0 .14 0 .187z'
            />
        </svg>
    );
};
