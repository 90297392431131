import { useState, useCallback, useEffect, MutableRefObject } from 'react';

const SCROLL_FACTOR = 50;

const useItemInView = (
    containerRef: MutableRefObject<any>,
    itemRefs: MutableRefObject<any[]>,
    onItemInView: (index: number) => void
): { handleScroll: () => void } => {
    const [itemInViewIdx, setItemInViewIdx] = useState(0);
    useEffect(() => onItemInView && onItemInView(itemInViewIdx), [itemInViewIdx]);

    const handleScroll = useCallback(() => {
        const listScrollLeft = containerRef.current.scrollLeft;
        const firstItemsWidth = itemRefs.current[0].current.clientWidth;
        if (listScrollLeft <= firstItemsWidth / 2) {
            setItemInViewIdx(0);
            return;
        }

        if (listScrollLeft + containerRef.current.offsetWidth >= containerRef.current.scrollWidth - SCROLL_FACTOR) {
            setItemInViewIdx(itemRefs.current.length - 1);
            return;
        }

        const reduceCompareMethod = (ref: MutableRefObject<any>): number => {
            return Math.abs(ref.current.offsetLeft - ref.current.clientWidth / 3 - listScrollLeft);
        };

        const selectedItem = itemRefs.current.reduce((prev, curr) => {
            return reduceCompareMethod(curr) < reduceCompareMethod(prev) ? curr : prev;
        });
        setItemInViewIdx(itemRefs.current.indexOf(selectedItem));
    }, []);

    return { handleScroll };
};

export default useItemInView;
