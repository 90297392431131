import React, { FunctionComponent, ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';

const Button = styled.button`
    font-size: 1.4rem;
    outline: none;
    min-width: 10rem;
    height: 4.6rem;
    border-radius: 1rem;
    box-shadow: 0 0.5rem 2.4rem 0 rgba(0, 0, 0, 0.05), 0 0.2rem 0.4rem 0 rgba(0, 0, 0, 0.03);
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: none;
    padding: 0 2rem;
    &:hover {
        border-color: #00aeef;
    }
    .text {
        z-index: 5;
    }
    span {
        cursor: pointer;
    }
    img {
        width: 2.5rem;
        margin-right: 1.5rem;
    }
    .text-wrapper {
        display: flex;
        flex-direction: column;
    }
`;

const MarkedButtonLine = styled.span`
    height: 0.5rem;
    width: 100%;
    background-color: #d2fa46;
    z-index: 4;
    margin-top: -0.5rem;
    border-radius: 1rem;
`;

const MarkedButton: FunctionComponent<ButtonHTMLAttributes<HTMLButtonElement> & { iconSrc?: string }> = (props) => (
    <Button {...props}>
        {props.iconSrc && <img src={props.iconSrc} />}
        <div className='text-wrapper'>
            <span className='text'>{props.children}</span>
            <MarkedButtonLine />
        </div>
    </Button>
);

export default MarkedButton;
