exports = module.exports = require("../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("./static/fonts/ApercuPro-LightItalic.woff2"));
var ___CSS_LOADER_URL___1___ = urlEscape(require("./static/fonts/ApercuPro-LightItalic.woff"));
var ___CSS_LOADER_URL___2___ = urlEscape(require("./static/fonts/ApercuPro-Regular.woff2"));
var ___CSS_LOADER_URL___3___ = urlEscape(require("./static/fonts/ApercuPro-Regular.woff"));
var ___CSS_LOADER_URL___4___ = urlEscape(require("./static/fonts/ApercuPro-Black.woff2"));
var ___CSS_LOADER_URL___5___ = urlEscape(require("./static/fonts/ApercuPro-Black.woff"));
var ___CSS_LOADER_URL___6___ = urlEscape(require("./static/fonts/ApercuPro-Light.woff2"));
var ___CSS_LOADER_URL___7___ = urlEscape(require("./static/fonts/ApercuPro-Light.woff"));
var ___CSS_LOADER_URL___8___ = urlEscape(require("./static/fonts/ApercuPro-Bold.woff2"));
var ___CSS_LOADER_URL___9___ = urlEscape(require("./static/fonts/ApercuPro-Bold.woff"));

// Module
exports.push([module.id, "@font-face {\n    font-family: 'Apercu Pro';\n    src: url(" + ___CSS_LOADER_URL___0___ + ") format('woff2'),\n        url(" + ___CSS_LOADER_URL___1___ + ") format('woff');\n    font-weight: 300;\n    font-style: italic;\n}\n\n@font-face {\n    font-family: 'Apercu Pro';\n    src: url(" + ___CSS_LOADER_URL___2___ + ") format('woff2'),\n        url(" + ___CSS_LOADER_URL___3___ + ") format('woff');\n    font-weight: normal;\n    font-style: normal;\n}\n\n@font-face {\n    font-family: 'Apercu Pro';\n    src: url(" + ___CSS_LOADER_URL___4___ + ") format('woff2'), url(" + ___CSS_LOADER_URL___5___ + ") format('woff');\n    font-weight: 900;\n    font-style: normal;\n}\n\n@font-face {\n    font-family: 'Apercu Pro';\n    src: url(" + ___CSS_LOADER_URL___6___ + ") format('woff2'), url(" + ___CSS_LOADER_URL___7___ + ") format('woff');\n    font-weight: 300;\n    font-style: normal;\n}\n\n@font-face {\n    font-family: 'Apercu Pro';\n    src: url(" + ___CSS_LOADER_URL___8___ + ") format('woff2'), url(" + ___CSS_LOADER_URL___9___ + ") format('woff');\n    font-weight: bold;\n    font-style: normal;\n}\n", ""]);

