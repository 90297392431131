import React, { FunctionComponent, useMemo, useCallback, useState } from 'react';
import { Section, SectionTitle } from '../../common/styles';
import behaviorsJson from '../components/SdkBehaviors/behaviors.json';
import SdkBehaviors, { SdkBehaviorItemParams } from '../components/SdkBehaviors';
import { BreakPoints } from '../../../common-styles';
import { requireResource } from '../../../utils';
import styled from 'styled-components';

const DEFAULT_SELECTED_INDEX = 0;

const behaviorIdToImageMap = {
    messaging: requireResource('messaging-instance.png'),
    proactive: requireResource('proactive-instance.png'),
    welcoming: requireResource('welcoming-instance.png'),
    highlightedText: requireResource('highlighted-text-instance.png'),
    wizard: requireResource('wizard-instance.png'),
};

const InnerContainer = styled.div`
    display: flex;
`;

const ImageContainer = styled.div`
    display: none;

    @media ${BreakPoints.desktop} {
        display: flex;
        margin-right: 50px;

        img {
            width: 635px;
            min-width: 635px;
            height: 460px;
        }
    }
`;

const SdkBehaviorsSection: FunctionComponent = () => {
    const behaviors = useMemo<SdkBehaviorItemParams[]>(() => behaviorsJson, []);
    const [currentBehavior, setCurrentBehavior] = useState(behaviors[DEFAULT_SELECTED_INDEX]);
    const behaviorImage = useMemo(() => behaviorIdToImageMap[currentBehavior.id], [currentBehavior]);

    const handleBehaviorSelection = useCallback(
        (index: number) => {
            setCurrentBehavior(behaviors[index]);
        },
        [behaviors, setCurrentBehavior]
    );

    return (
        <Section>
            <SectionTitle>
                The SDK supports various behaviors, each with its unique
                <br />
                set of capabilities to be useful in different use cases:
            </SectionTitle>
            <InnerContainer>
                <ImageContainer>
                    <img src={behaviorImage} alt='sdk behavior example' />
                </ImageContainer>
                <SdkBehaviors
                    behaviors={behaviors}
                    onSelectedItem={handleBehaviorSelection}
                    defaultSelectedIndex={DEFAULT_SELECTED_INDEX}
                />
            </InnerContainer>
        </Section>
    );
};

export default SdkBehaviorsSection;
