import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { BreakPoints } from '../../../../common-styles';
import { Principle } from './types';

const PrincipleItemContainer = styled.li`
    text-align: center;

    max-width: 300px;
    margin: 0 auto;

    &:not(:last-child) {
        margin-bottom: 40px;
    }

    @media ${BreakPoints.desktop} {
        width: 400px;
        max-width: initial;
        margin: 0 60px 0 0;

        &:not(:last-child) {
            margin: 0 60px 0 0;
        }
    }
`;

const PrincipleItemImageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 10px;

    height: 200px;

    img {
        max-width: 100%;
        max-height: 156px;
    }

    @media ${BreakPoints.desktop} {
        margin-bottom: 32px;
    }
`;

const PrincipleItemTitle = styled.h3`
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 10px;

    @media ${BreakPoints.desktop} {
        font-size: 2.6rem;
        margin-bottom: 16px;
    }
`;

const PrincipleItemDescription = styled.p`
    font-size: 1.6rem;

    @media ${BreakPoints.desktop} {
        font-size: 2rem;
    }
`;

const PrincipleItem: FunctionComponent<Principle> = ({ iconSrc, title, description }) => (
    <PrincipleItemContainer>
        <PrincipleItemImageContainer>
            <img src={iconSrc} draggable={false} />
        </PrincipleItemImageContainer>
        <PrincipleItemTitle>{title}</PrincipleItemTitle>
        <PrincipleItemDescription>{description}</PrincipleItemDescription>
    </PrincipleItemContainer>
);

export default PrincipleItem;
