import React from 'react';

export default (): JSX.Element => (
    <svg xmlns='http://www.w3.org/2000/svg' width='76' height='76' fill='none' viewBox='0 0 76 76'>
        <path
            fill='#D2FA46'
            className='svg-circle'
            d='M17.846 50.761c-.195-5.843 5.854-8.602 9.601-9.252 2.2-.525 6.399-.817 10.014 0 4.355.985 5.518 4.638 6.131 9.252.613 4.614.205 8.377-2.452 11.827-2.49 3.234-10.703 3.33-15.95 1.926a7.072 7.072 0 0 1-1.925-.838c-4.697-2.943-5.419-6.554-5.419-12.915z'
        />
        <path
            fill='#333F48'
            d='M40.457 27.082c-.093-1.212-.186-2.097-.186-2.983-.047-1.63.93-2.61 2.559-2.563 1.395.047 2.838.187 4.234.373 1.303.14 2.187 1.212 2.28 2.703.186 2.843.325 5.685.418 8.527.047.84.28 1.398.977 1.911 2.746 2.19 5.49 4.427 8.236 6.617.791.606 1.582 1.258 1.21 2.423-.372 1.212-1.35 1.678-2.606 1.678-1.442.046-2.838.233-4.28.14-1.257-.094-1.49.465-1.49 1.537-.092 4.893-.278 9.786-.418 14.679-.047 1.864-.093 3.728-.186 5.592-.047 1.305-.791 2.097-1.815 2.05-.977-.047-1.628-.979-1.582-2.237.186-7.455.559-14.958.559-22.414 0-1.584.884-2.47 2.466-2.563a65.815 65.815 0 0 1 3.117-.186c.14-.42-.326-.513-.558-.699-1.768-1.445-3.49-2.889-5.305-4.24-1.21-.932-1.721-2.097-1.814-3.542-.14-2.423-.28-4.893-.372-7.316-.047-.699-.187-1.118-1.024-1.211-.838-.094-1.024.14-.93.931.139 1.212.139 2.424.232 3.589.093 1.071-.326 1.864-1.21 2.33-.884.512-1.721.14-2.42-.513-3.675-3.262-7.35-6.477-10.98-9.74-.512-.465-.837-.745-1.442-.046L7.887 43.997c-.093.14-.186.28-.373.513 1.117.046 2.14.093 3.118.14 1.721.093 2.606 1.164 2.559 2.889-.186 6.15-.326 12.255-.465 18.406 0 .28 0 .513-.047.792-.14 1.072-.651 1.864-1.815 1.957-.977.047-1.721-.838-1.721-2.143 0-2.983.093-5.965.14-8.947.046-2.75.046-5.545.186-8.295.046-1.072-.373-1.304-1.303-1.258-1.163.047-2.327-.047-3.443-.047-1.07 0-2.094-.186-2.56-1.304-.465-1.072.14-1.864.838-2.61a10286.67 10286.67 0 0 0 21.962-24.045 70.051 70.051 0 0 0 1.675-1.91c1.256-1.352 2.14-1.352 3.536-.14 3.07 2.75 6.142 5.545 9.26 8.294.278.233.51.42 1.023.793z'
        />
    </svg>
);
