import React, { FunctionComponent } from 'react';
import WobbleFilter from '../WobbleFilter';
import useWobble from '../useWobble';

const LinkBoxWobble: FunctionComponent = () => {
    const { filterRef, vectorId } = useWobble();
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%' fill='none'>
            <g>
                <WobbleFilter animateRef={filterRef} id={vectorId} baseFrequency='0.06' />
                <rect
                    width='100%'
                    height='100%'
                    fill='#D2FA46'
                    rx='10'
                    style={{
                        filter: `url(#${vectorId})`,
                    }}
                />
            </g>
        </svg>
    );
};

export default LinkBoxWobble;
