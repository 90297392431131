import React, { FunctionComponent } from 'react';
import { Section, HighlightedButton } from '../../common/styles';
import { BreakPoints } from '../../../common-styles';
import { Link as ScrollLink } from 'react-scroll';
import styled from 'styled-components';
import { requireResources } from '../../../utils';
const [knowledge, greenStrokeImage] = requireResources(['knowledge.png', 'green-stroke.svg']);

const SITE_DESCRIPTION_TEXT =
    'The SDK provides a consistent solution for any website or app, allowing users to interact with Asurion’s experts in various flows and contexts.';

const MainImageContainer = styled.div`
    display: flex;
    justify-content: center;

    margin: 10px auto;

    img {
        max-width: 200px;
    }

    @media ${BreakPoints.desktop} {
        margin: 70px 0 22px;

        img {
            max-width: initial;
        }
    }
`;

const TitleContainer = styled.div`
    margin-bottom: 20px;
    @media ${BreakPoints.desktop} {
        margin-bottom: 32px;
    }
`;

const SiteTitle = styled.h1`
    font-size: 2.3rem;
    font-weight: 600;

    @media ${BreakPoints.desktop} {
        display: inline-flex;
        position: relative;
        font-size: 6rem;
    }
`;

const EmphasizedTitleStrokeContainer = styled.span`
    display: none;

    @media ${BreakPoints.desktop} {
        display: inline-flex;
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: -1;
        transform: translate3d(9px, 3px, 0);
    }
`;

const SiteDescription = styled.p`
    font-size: 1.8rem;
    margin-bottom: 20px;
    @media ${BreakPoints.desktop} {
        font-size: 2.6rem;
        max-width: 65%;
        margin: 0 auto;
        margin-bottom: ${({ theme }) => theme.sizes.desktopSectionElementsSpacing};
    }
`;

const LinkContainer = styled.div`
    display: flex;
    justify-content: center;
`;

const IntroSection: FunctionComponent = () => (
    <Section id='introSection'>
        <MainImageContainer>
            <img src={knowledge} alt='character reading a book' draggable={false} />
        </MainImageContainer>
        <TitleContainer>
            <SiteTitle>
                Welcome to Anywhere
                <br />
                Expert SDK knowledge hub
                <EmphasizedTitleStrokeContainer>
                    <img src={greenStrokeImage} alt='green stroke' draggable={false} />
                </EmphasizedTitleStrokeContainer>
            </SiteTitle>
        </TitleContainer>
        <SiteDescription>{SITE_DESCRIPTION_TEXT}</SiteDescription>
        <LinkContainer>
            <ScrollLink to='docsSection' smooth offset={-200}>
                <HighlightedButton>Let's get started</HighlightedButton>
            </ScrollLink>
        </LinkContainer>
    </Section>
);
export default IntroSection;
