import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { BreakPoints } from '../../../../common-styles';
import { Principle } from './types';
import PrincipleItem from './PrincipleItem';

export * from './types';

const StyledPrincipleList = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: center;

    @media ${BreakPoints.desktop} {
        flex-direction: row;
        justify-content: center;
    }
`;

type Props = {
    principles: Principle[];
};

const Principles: FunctionComponent<Props> = ({ principles }) => (
    <StyledPrincipleList>
        {principles.map((principle, index) => (
            <PrincipleItem key={index} {...principle} />
        ))}
    </StyledPrincipleList>
);

export default Principles;
