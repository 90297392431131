import React, { FunctionComponent } from 'react';
import { SectionTitle, ContentAdjustedContainer, Section, FluidContainer } from '../../common/styles';
import Principles, { Principle } from '../components/Principles';
import { requireResources } from '../../../utils';
import styled from 'styled-components';
import { BreakPoints } from '../../../common-styles';

const Container = styled(FluidContainer)`
    padding-bottom: 100px;
    padding-top: 0px;
    background: ${({ theme }) => theme.colors.lightgray};

    @media ${BreakPoints.desktopContentWidth} {
        padding-top: 100px;
    }
`;

const [oneServiceIcon, flexibilityIcon, simplicityIcon] = requireResources([
    'one-service.png',
    'flexibility.png',
    'simplicity.png',
]);

const principles: Principle[] = [
    {
        iconSrc: oneServiceIcon,
        title: 'One service',
        description: 'A continuous experience for customers to have in all of their touchpoints with Asurion',
    },
    {
        iconSrc: flexibilityIcon,
        title: 'Flexibility',
        description: 'Adjustable & customizable to support different flows and partners.',
    },
    {
        iconSrc: simplicityIcon,
        title: 'Simplicity',
        description: 'Fast & simple to add to any website, regardless of the tech stack.',
    },
];

const PrinciplesSection: FunctionComponent = () => (
    <Section>
        <Container>
            <ContentAdjustedContainer>
                <SectionTitle>
                    The SDK's core principles are providing
                    <br /> unified experiences for Asurion's offerings
                </SectionTitle>
                <div>
                    <Principles principles={principles} />
                </div>
            </ContentAdjustedContainer>
        </Container>
    </Section>
);

export default PrinciplesSection;
