import { Colors, Sizes } from '../theme';

export const colors: Colors = {
    purple: '#8223d2',
    lightgreen: '#d2fa46',
    lightgray: '#F0F0F5',
    gray: '#6c747b',
    appBlack: '#000000',
};

export const sizes: Sizes = {
    desktopContentWidth: '1320px',
    desktopHeaderHeight: '80px',
    desktopSectionElementsSpacing: '60px',
    desktopSectionSpacing: '200px',
    desktopSectionColumnHorizontalSpacing: '50px',
    mobileHeaderHeight: '70px',
};
