import React, { FunctionComponent, useCallback } from 'react';
import { BreakPoints } from '../../../../common-styles';
import { SdkBehaviorItemParams } from '.';
import { requireResource } from '../../../../utils';
import jelloAnimation from './jelloAnimation';
import styled, { css } from 'styled-components';

const quirkyRectangleImage = requireResource('quirky-rectangle.svg');

const StyledListItem = styled.li<{ isSelected?: boolean }>`
    position: relative;
    padding: 15px;
    border-radius: 20px;
    background-color: ${({ theme }) => theme.colors.lightgray};
    transition: background-color 0.2s;
    text-align: left;

    &:not(:last-child) {
        margin-bottom: 16px;
    }

    ${({ isSelected, theme }) =>
        isSelected &&
        css`
            background-color: ${theme.colors.lightgreen};
        `}

    @media ${BreakPoints.desktop} {
        background-color: transparent;
        cursor: pointer;
        padding: 16px 0 16px 24px;

        ${({ isSelected, theme }) =>
            !isSelected &&
            css`
                &:hover {
                    background-color: ${theme.colors.lightgray};
                }
            `}

        ${({ isSelected, theme }) =>
            isSelected &&
            css`
                background-color: ${theme.colors.lightgreen};
            `}
    }
`;

const Title = styled.h3<{ isSelected?: boolean }>`
    font-size: 1.9rem;
    ${({ isSelected, theme }) =>
        isSelected &&
        css`
            color: ${theme.colors.appBlack};
            font-weight: 600;
        `}

    @media ${BreakPoints.desktop} {
        font-size: 2.6rem;
    }
`;

const Description = styled.p`
    font-size: 1.6rem;
    margin-top: 7px;

    @media ${BreakPoints.desktop} {
        font-size: 2rem;
        margin-top: 16px;
        max-width: 70%;
    }
`;

const ComingSoonLabelContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    transform: translate3d(-7px, -9px, 0);
    z-index: 1;

    font-size: 1.2rem;
    font-weight: 600;
    color: white;

    @media ${BreakPoints.desktopContentWidth} {
        transform: translate3d(7px, 16px, 0);
        color: white;
    }
`;

const ComingSoonInnerContainer = styled.div`
    width: 100%;
    height: 100%;

    width: 87px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform-origin: center;

    animation-name: ${jelloAnimation};
    animation-duration: 1s;
`;

const ComingSoonBackground = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    background-image: url(${quirkyRectangleImage});
    background-size: contain;
    background-repeat: no-repeat;
`;

export type SdkBehaviorItemProps = {
    index: number;
    isSelected?: boolean;
    onClick?: (index: number) => void;
} & Omit<SdkBehaviorItemParams, 'id'>;

const SdkBehaviorItem: FunctionComponent<SdkBehaviorItemProps> = ({
    title,
    description,
    index,
    isSelected,
    onClick,
    isAvailable,
}) => {
    const handleClick = useCallback(() => {
        onClick && onClick(index);
    }, [index, onClick]);

    return (
        <StyledListItem onClick={handleClick} isSelected={isSelected}>
            <Title isSelected={isSelected}>{title}</Title>
            {isSelected && <Description>{description}</Description>}
            {isSelected && !isAvailable && (
                <ComingSoonLabelContainer>
                    <ComingSoonInnerContainer>
                        <span>Coming soon</span>
                        <ComingSoonBackground />
                    </ComingSoonInnerContainer>
                </ComingSoonLabelContainer>
            )}
        </StyledListItem>
    );
};

export default SdkBehaviorItem;
