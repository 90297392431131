import { BreakPoints } from '../../common-styles';
import styled from 'styled-components';

export const HighlightedButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 157px;
    height: 40px;
    color: #fff;
    background-color: ${({ theme }) => theme.colors.purple};
    border-radius: 40px;

    font-size: 1.6rem;
    font-weight: 600;

    @media ${BreakPoints.desktop} {
        cursor: pointer;
        width: 207px;
        height: 60px;
        font-size: 2rem;
    }
`;

export const Section = styled.section`
    width: 100%;
    margin-bottom: 100px;
    padding: 0 15px;

    @media ${BreakPoints.desktopContentWidth} {
        padding: 0;
        margin-bottom: ${({ theme }) => theme.sizes.desktopSectionSpacing};
    }
`;

export const FluidContainer = styled.div`
    @media ${BreakPoints.desktopContentWidth} {
        ${({ theme }) => `
            {
                margin-left: calc(-100vw / 2 + ${theme.sizes.desktopContentWidth} / 2);
                margin-right: calc(-100vw / 2 + ${theme.sizes.desktopContentWidth} / 2);
            }
        `}
    }
`;

export const ContentAdjustedContainer = styled.div`
    @media ${BreakPoints.desktop} {
        max-width: ${({ theme }) => theme.sizes.desktopContentWidth};
        margin: 0 auto;
    }
`;

export const SectionTitle = styled.h2`
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 50px;

    br {
        display: none;
    }

    @media ${BreakPoints.desktop} {
        font-size: 3rem;
        margin-bottom: ${({ theme }) => theme.sizes.desktopSectionElementsSpacing};

        br {
            display: initial;
        }
    }
`;
