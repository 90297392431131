import React, { useState, FunctionComponent } from 'react';
import { BreakPoints } from '../common-styles';
import styled from 'styled-components';

const IndicatorContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    z-index: 999999999;
    opacity: 0.6;
    height: auto;
    font-size: 0.875rem;
    &:hover {
        opacity: 1;
    }

    .mobile {
        display: block;
    }

    @media ${BreakPoints.tablet} {
        .tablet {
            display: block;
        }
        .mobile-big,
        .mobile {
            display: none;
        }
    }

    @media ${BreakPoints.desktop} {
        .desktop {
            display: block;
        }
        .mobile,
        .mobile-big,
        .tablet-big,
        .tablet {
            display: none;
        }
    }
`;

const Indicator = styled.label`
    display: none;
    color: white;
    margin: auto;
    padding: 2px 5px;
`;

const Desktop = styled(Indicator)`
    background-color: blue;
`;

const Tablet = styled(Indicator)`
    background-color: lightblue;
`;

const Mobile = styled(Indicator)`
    background-color: purple;
`;

const ScreenDimensions = styled(Indicator)`
    display: block;
    padding: 2px 5px;
    background-color: yellow;
    color: black;
`;

const Comp: FunctionComponent = () => {
    const pickScreenDimensions = (): string => `${window.innerWidth}x${window.innerHeight}`;
    const [screenDimensions, setScreenDimensions] = useState(pickScreenDimensions());
    window.addEventListener('resize', () => {
        setScreenDimensions(pickScreenDimensions());
    });

    return (
        <IndicatorContainer>
            <Desktop className='desktop'>desktop</Desktop>
            <Tablet className='tablet'>tablet</Tablet>
            <Mobile className='mobile'>mobile</Mobile>
            <ScreenDimensions>{screenDimensions}</ScreenDimensions>
        </IndicatorContainer>
    );
};

export default Comp;
