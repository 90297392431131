type BreakPoints<T = string> = {
    desktopContentWidth?: T;
    desktop?: T;
    tablet?: T;
    tabletBig?: T;
    mobile?: T;
    mobileBig?: T;
};

const BREAK_POINTS_DIMENSIONS_VALUES_IN_PX: BreakPoints<number> = {
    desktopContentWidth: 1320,
    desktop: 1024,
    tabletBig: 900,
    tablet: 768,
    mobileBig: 481,
    mobile: 0,
};

const BREAK_POINTS: BreakPoints = Object.entries(BREAK_POINTS_DIMENSIONS_VALUES_IN_PX).reduce((prev, [key, value]) => {
    prev[key] = `(min-width: ${value}px)`;
    return prev;
}, {});

export default BREAK_POINTS;
