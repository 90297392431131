import React, { FunctionComponent, forwardRef } from 'react';
import { BreakPoints } from '../../common-styles';
import styled from 'styled-components';

const StyledItem = styled.li`
    margin-right: 2rem;
`;

const StyledImage = styled.img`
    user-select: none;
    max-width: 150vw;

    &:last-child {
        padding-right: 3rem;
    }
    @media ${BreakPoints.desktop} {
        max-width: 84.8rem;
    }
`;

type Props = {
    imageSrc: string;
};

const CarouselImageItem: FunctionComponent<Props> = ({ imageSrc }, ref) => (
    <StyledItem className='ae-sdk-carousel-item' ref={ref}>
        <StyledImage src={imageSrc} draggable={false} />
    </StyledItem>
);

export default forwardRef(CarouselImageItem);
