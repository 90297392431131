import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { BreakPoints } from '../../../../common-styles';
import { Capability } from './types';

const CapabilityItemContainer = styled.li`
    display: flex;
    width: 100%;
    text-align: left;
    margin-bottom: 50px;

    @media ${BreakPoints.desktop} {
        width: 400px;
        margin-bottom: 23px;
    }
`;

const CapabilityItemLeftHandContainer = styled.div`
    margin-right: 10px;

    @media ${BreakPoints.desktop} {
        margin-right: 18px;
    }
`;

const CapabilityItemRightHandContainer = styled.div`
    @media ${BreakPoints.desktop} {
        width: 300px;
        height: 110px;
    }
`;

const CapabilityItemIndicator = styled.div`
    width: 15px;
    height: 15px;
    background-color: transparent;

    @media ${BreakPoints.desktop} {
        width: 32px;
        height: 32px;
    }
`;

const CapabilityItemTitle = styled.h3`
    font-size: 1.6rem;
    font-weight: 600;
    margin-bottom: 6px;
    height: 15px;
    line-height: 15px;
    white-space: nowrap;

    @media ${BreakPoints.desktop} {
        font-size: 2rem;
        height: 32px;
        line-height: 32px;
        margin-bottom: 16px;
    }
`;

const CapabilityItemDescription = styled.p`
    font-size: 1.4rem;

    @media ${BreakPoints.desktop} {
        font-size: 1.6rem;
    }
`;

const CapabilityItem: FunctionComponent<Capability> = ({ title, description }) => (
    <CapabilityItemContainer>
        <CapabilityItemLeftHandContainer>
            <CapabilityItemIndicator />
        </CapabilityItemLeftHandContainer>
        <CapabilityItemRightHandContainer>
            <CapabilityItemTitle>{title}</CapabilityItemTitle>
            <CapabilityItemDescription>{description}</CapabilityItemDescription>
        </CapabilityItemRightHandContainer>
    </CapabilityItemContainer>
);

export default CapabilityItem;
