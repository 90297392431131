import React, { FunctionComponent } from 'react';
import BreakPoints from './media-queries';
import assets from './assets';
import * as variables from './variables';
import { ThemeProps, Theme } from '../theme';
import { ThemeProvider } from 'styled-components';

declare module 'styled-components' {
    export interface DefaultTheme extends Theme {}
}

const AppThemeProvider: FunctionComponent<ThemeProps> = ({ theme, children }) => (
    <ThemeProvider theme={theme}>
        <>{children}</>
    </ThemeProvider>
);

export { AppThemeProvider as ThemeProvider, BreakPoints, variables, assets };
