import React, { FunctionComponent } from 'react';
import { BreakPoints } from '../common-styles';
import styled from 'styled-components';

const ButtonContainer = styled.button`
    outline: none;
    border: 0;
    font-family: inherit;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 10px;
    width: 161px;
    height: 40px;
    user-select: none;
    box-shadow: 0 5px 24px 0 rgba(0, 0, 0, 0.05), 0 2px 4px 0 rgba(0, 0, 0, 0.03);

    @media ${BreakPoints.desktop} {
        height: 50px;
    }
`;

const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const IconImage = styled.img`
    max-width: 2.5rem;
`;

const Text = styled.span`
    position: relative;
    z-index: 1;
    font-size: 1.4rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.appBlack};
    margin-left: 1.5rem;
    padding: 0 0.2rem;

    &:after {
        content: '';
        z-index: -1;
        height: 0.5rem;
        width: 100%;
        background-color: ${({ theme }) => theme.colors.lightgreen};
        position: absolute;
        left: 0;
        bottom: 0.2rem;
        border-radius: 1rem;
    }

    @media ${BreakPoints.desktop} {
        font-size: 1.6rem;
    }
`;

type Props = {
    text: string;
    iconSrc: string;
    className?: string;
    onClick?: VoidFunction;
};

const SiteButton: FunctionComponent<Props> = ({ text, iconSrc, className, onClick }) => (
    <ButtonContainer onClick={onClick} className={className}>
        <Content>
            <IconImage src={iconSrc} />
            <Text>{text}</Text>
        </Content>
    </ButtonContainer>
);

export default SiteButton;
