import React, { FunctionComponent } from 'react';
import { BreakPoints } from '../../../common-styles';
import { Section, SectionTitle, FluidContainer } from '../../common/styles';
import { requireResources } from '../../../utils';
import { externalLinks } from '../data';
import styled from 'styled-components';

const [nativeApiDocImage, webApiDocImage] = requireResources(['native-api-documentation.png', 'web-api-documentation.png']);

const ExternalLinkContainer = styled.div`
    display: flex;
    margin-top: ${({ theme }) => theme.sizes.desktopSectionElementsSpacing};
    justify-content: center;
    flex-direction: column;

    @media ${BreakPoints.desktopContentWidth} {
        flex-direction: row;
    }
`;

const ExternalLink = styled.a`
    margin-right: 0px;
    margin-bottom: 50px;
    &,
    &:hover,
    &:visited {
        text-decoration: none;
    }

    @media ${BreakPoints.desktopContentWidth} {
        flex-direction: row;
        margin-right: 50px;
        margin-bottom: 0px;

        &:last-child {
            margin-right: 0px;
        }
    }
`;

const DocumentContentContainer = styled.div`
    margin-right: 40px;
    text-align: left;
`;

const DocumentTypeContainer = styled.div`
    display: flex;
    flex-direction: column;
    color: ${({ theme }) => theme.colors.appBlack};
    box-shadow: 0px 5px 24px rgba(0, 0, 0, 0.05), 0px 2px 4px rgba(0, 0, 0, 0.03);
    border-radius: 20px;
    padding: 33px 38px 26px 60px;

    @media ${BreakPoints.desktopContentWidth} {
        flex-direction: row;
        min-height: 319px;
    }
`;

const DocumentTitle = styled.p`
    font-size: 2.6rem;
    font-weight: 600;
`;

const DocumentDescription = styled.p`
    margin-top: 16px;
    font-size: 1.6rem;

    @media ${BreakPoints.desktopContentWidth} {
        font-size: 2rem;
        width: 240px;
    }
`;

const SectionDescription = styled.p`
    margin: 0 auto;
    font-size: 1.6rem;

    @media ${BreakPoints.desktopContentWidth} {
        font-size: 2rem;
    }
`;

const StyledImage = styled.img`
    height: fit-content;
`;

const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 50px;
    @media ${BreakPoints.desktopContentWidth} {
        margin-top: 0px;
    }
`;

const FluidStyledContainer = styled(FluidContainer)`
    margin-right: 20px;
    margin-left: 20px;
`;

const DocumentationSection: FunctionComponent = () => (
    <Section id='docsSection'>
        <FluidStyledContainer>
            <SectionTitle>Hosting the SDK</SectionTitle>
            <SectionDescription>
                We do our best to document every little thing to help make the SDK’s hosting as effortless
                <br />
                as possible, always editing and improving to remove any unnecessary friction.
            </SectionDescription>
            <ExternalLinkContainer>
                <ExternalLink href={externalLinks.webApiDocumentation} target='__blank'>
                    <DocumentTypeContainer>
                        <DocumentContentContainer>
                            <DocumentTitle>Web API</DocumentTitle>
                            <DocumentDescription>
                                Integrate the SDK in any website, whether it’s desktop or mobile.
                            </DocumentDescription>
                        </DocumentContentContainer>
                        <ImageContainer>
                            <StyledImage src={webApiDocImage} />
                        </ImageContainer>
                    </DocumentTypeContainer>
                </ExternalLink>
                <ExternalLink href={externalLinks.nativeApiDocumentation} target='__blank'>
                    <DocumentTypeContainer>
                        <DocumentContentContainer>
                            <DocumentTitle>Native API</DocumentTitle>
                            <DocumentDescription>
                                Check out the documentation to see what you need to do to integrate the SDK in iPhone or Android
                                native apps.
                            </DocumentDescription>
                        </DocumentContentContainer>
                        <ImageContainer>
                            <StyledImage src={nativeApiDocImage} />
                        </ImageContainer>
                    </DocumentTypeContainer>
                </ExternalLink>
            </ExternalLinkContainer>
        </FluidStyledContainer>
    </Section>
);

export default DocumentationSection;
