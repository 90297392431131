import React, { FunctionComponent } from 'react';
import SdkBehaviorItem, { SdkBehaviorItemProps } from './SdkBehaviorItem';
import styled from 'styled-components';

type SdkBehaviorsListProps = {
    items: Pick<SdkBehaviorItemProps, 'title' | 'description' | 'isAvailable'>[];
    selectedIndex: number;
    onItemClick?: (index: number) => void;
};

const StyledList = styled.ul`
    width: 100%;
`;

const SdkBehaviorsList: FunctionComponent<SdkBehaviorsListProps> = ({ items, selectedIndex, onItemClick }) => (
    <StyledList>
        {items.map((item, index) => (
            <SdkBehaviorItem {...item} key={index} index={index} isSelected={selectedIndex === index} onClick={onItemClick} />
        ))}
    </StyledList>
);
export default SdkBehaviorsList;
