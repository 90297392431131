import { useRef, useEffect, useMemo, RefObject } from 'react';
import bowser from 'bowser';
import { WobbleRefActions } from './WobbleFilter';
const platform = bowser.getParser(window.navigator.userAgent).getPlatformType();
const isMobilePlatform = platform.toLowerCase() === 'mobile';

const randomStr = (): string => Math.random().toString(16).substring(7);

const useWobble = (): {
    vectorId: string;
    filterRef: RefObject<WobbleRefActions>;
} => {
    const vectorId = useMemo(() => `wobble-${randomStr()}`, []);
    const filterRef = useRef<WobbleRefActions>(null);

    useEffect(() => {
        !isMobilePlatform && filterRef.current && filterRef.current.play();
    }, []);

    return { vectorId, filterRef };
};

export default useWobble;
