import React, { FunctionComponent, useCallback, useState, useEffect } from 'react';
import SdkBehaviorsList from './SdkBehaviorsList';

export type SdkBehaviorItemParams = {
    id: string;
    title: string;
    description: string;
    isAvailable: boolean;
};

type SdkBehaviorsProps = {
    behaviors: SdkBehaviorItemParams[];
    onSelectedItem?: (index: number) => void;
    defaultSelectedIndex?: number;
};

const SdkBehaviors: FunctionComponent<SdkBehaviorsProps> = ({ behaviors, onSelectedItem, defaultSelectedIndex = 0 }) => {
    const [selectedItemIndex, setSelectedItemIndex] = useState(defaultSelectedIndex);

    const handleItemSelection = useCallback(
        (index: number) => {
            setSelectedItemIndex(index);
        },
        [setSelectedItemIndex]
    );

    useEffect(() => {
        onSelectedItem && onSelectedItem(selectedItemIndex);
    }, [selectedItemIndex, onSelectedItem]);

    return <SdkBehaviorsList items={behaviors} onItemClick={handleItemSelection} selectedIndex={selectedItemIndex} />;
};

export default SdkBehaviors;
