import './reset.css';
import './fonts.css';
import './index.css';
import './initialization';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from './App';

ReactDOM.render(
    <>
        <App />
    </>,
    document.getElementById('root') as HTMLElement
);
